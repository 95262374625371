<template>
  
  <main className="py-10" v-if="data">
    <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
      <div className="flex items-center space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <DynamicImage v-if="data.Photos" :source="data.Photos" class="w-16 h-16 rounded-full lg:w-20 lg:h-20" />
            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
          </div>
        </div>
        <div>
          <h1 className="text-2xl font-bold text-gray-900">{{data.first_name}} {{data.last_name}}</h1>
          <!-- <p className="text-sm font-medium text-gray-500">
            Applied for{' '}
            <a href="#" className="text-gray-900">
              Front End Developer
            </a>
            on <time dateTime="2020-08-25">August 25, 2020</time>
          </p> -->
        </div>
      </div>
      <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <a :href="'mailto:'+data.email" class="bg-white rounded-full hover:bg-orange-500 hover:text-white border-2 mr-4 mb-4 border-orange-500 text-gray-800 font-bold py-1 px-4 rounded inline-flex items-center">
          <Email32 class="fill-current w-4 h-4 mr-2"/>
          <span>Email</span>
        </a>
        <a :href="'tel:'+data.phone" class="bg-white rounded-full hover:bg-orange-500 hover:text-white border-2 mr-4 mb-4 border-orange-500 text-gray-800 font-bold py-1 px-4 rounded inline-flex items-center">
          <PhoneOutgoingFilled32 class="fill-current w-4 h-4 mr-2"/>
          <span>Bel</span>
        </a>
      </div>
    </div>

    <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
      <div className="space-y-6 lg:col-span-2 lg:col-start-1">

        <section aria-labelledby="applicant-information-title">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
                Details
              </h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                <template v-if="data.BusinessUnits">
                  {{data.BusinessUnits.map(e => e.title).join(', ')}}
                </template>
              </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Email address</dt>
                  <dd className="mt-1 text-sm text-gray-900">{{data.email}}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Phone</dt>
                  <dd className="mt-1 text-sm text-gray-900">{{data.phone}}</dd>
                </div>
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">About</dt>
                  <dd className="mt-1 text-sm text-gray-900 whitespace-pre-line">
                    {{data.internal_intro}}
                  </dd>
                </div>
              </dl>
            </div>
            <!-- <div>
              <a
                href="#"
                className="block bg-gray-50 px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg"
              >
                Read full application
              </a>
            </div> -->
          </div>
        </section>

        
      </div>

      <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">
        <!-- <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
          <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
            Timeline
          </h2>

          <div className="mt-6 flow-root">
            <ul role="list" className="-mb-8">
              
            </ul>
          </div>
          <div className="justify-stretch mt-6 flex flex-col">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Advance to offer
            </button>
          </div>
        </div> -->
      </section>
    </div>
  </main>

</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from 'vue-router'
import RecentUserService from '../../services/admin/users/recent_user_service';
import DynamicImage from '../../components/DynamicImage.vue'
import { PhoneOutgoingFilled32, Email32 } from '@carbon/icons-vue';

export default {
  name: 'SmoelenboekProfile',
  components: {
    DynamicImage,
    Email32,
    PhoneOutgoingFilled32
  },
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute()
    const id = route.params.id;

    function fetchData() {
      loading.value = true;
      // I prefer to use fetch
      // you can use use axios as an alternative
      return RecentUserService.getUserDetails(id).then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.data;
        })
        .then(json => {
          // set the response data
          data.value = json;
        })
        .catch(err => {
          error.value = err;
          // In case a custom JSON error response was provided
          if (err.json) {
            return err.json.then(json => {
              // set the JSON response message
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }
    onMounted(() => {
      fetchData();
    });

    return {
      data,
      loading,
      error
    };
  }
}
</script>